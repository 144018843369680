import { useFormat } from 'helpers/hooks/useFormat';

interface Props {
  type: 'nipError' | 'errorFetchingStores' | 'errorCreatingAccount';
}

const ErrorFeedback = ({ type }: Props) => {
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  let message = '';
  if (type === 'nipError')
    message = formatErrorMessage({
      id: 'form.nipFetch',
      defaultMessage: 'Error retrieving NIP, please check the field and try again later',
    });
  if (type === 'errorFetchingStores')
    message = formatErrorMessage({
      id: 'form.storesFetch',
      defaultMessage: 'Error fetching stores, please try again later',
    });
  if (type === 'errorCreatingAccount')
    message = formatErrorMessage({
      id: 'form.accountError',
      defaultMessage: 'Sorry, we could not create your account.',
    });

  return (
    <div className="mt-24 text-red-500">
      <p className="font-bold">{message}</p>
    </div>
  );
};

export default ErrorFeedback;
