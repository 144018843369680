import { AccountRegisterBody } from 'shared/types/account';
import { Nip24CompanyInfo } from 'shared/types/account/Nip24CompanyInfo';
import { DataB2B, DataB2C } from 'components/commercetools-ui/organisms/authentication/registration/types';

export function mapFormDataToAccountRegisterBodyB2B(data: DataB2B): AccountRegisterBody {
  // Define fields that need to be ignored from the form data object
  const toIgnore = ['nipData', 'nipId', 'passwordConfirmation', 'phone'];

  // Create data object without properties defined in "toIgnore"
  const filteredFormData = Object.keys(data)
    .filter((key) => (toIgnore.includes(key) ? false : true))
    .reduce<DataB2B>((acc, key) => ({ ...acc, [key]: data[key as keyof DataB2B] }), {} as DataB2B);

  // Create 'addresses' array with a single default address object to add to final data object
  const addresses = [
    {
      city: data.nipData.city,
      firstName: data.firstName,
      isBillingAddress: true,
      isDefaultBillingAddress: true,
      isDefaultShippingAddress: true,
      isShippingAddress: true,
      lastName: data.lastName,
      phone: data.phone,
      postalCode: data.nipData.postalCode,
      streetName: data.nipData.streetName,
      streetNumber: data.nipData.streetNumber,
    },
  ];

  // Filter NIP data object to ignore fields already used in the address created above
  const toIgnoreNipData = ['city', 'postalCode', 'streetName', 'streetNumber'];
  const filteredNipData = Object.keys(data.nipData)
    .filter((key) => (toIgnoreNipData.includes(key) ? false : true))
    .reduce((acc, key) => ({ ...acc, [key]: data.nipData[key as keyof Nip24CompanyInfo] }), {});

  // Join everything into the final B2B object
  const mappedData = {
    ...filteredFormData,
    ...filteredNipData,
    addresses,
  };

  return mappedData;
}

export function mapFormDataToAccountRegisterBodyB2C(data: DataB2C): AccountRegisterBody {
  // Define fields that need to be ignored from the form data object
  const toIgnore = ['city', 'postalCode', 'streetName', 'streetNumber', 'passwordConfirmation', 'phone'];

  // Create data object without properties defined in "toIgnore"
  const filteredFormData = Object.keys(data)
    .filter((key) => (toIgnore.includes(key) ? false : true))
    .reduce<DataB2C>((acc, key) => ({ ...acc, [key]: data[key as keyof DataB2C] }), {} as DataB2C);

  // Create 'addresses' array with a single default address object to add to final data object
  const addresses = [
    {
      city: data.city,
      firstName: data.firstName,
      isBillingAddress: true,
      isDefaultBillingAddress: true,
      isDefaultShippingAddress: true,
      isShippingAddress: true,
      lastName: data.lastName,
      phone: data.phone,
      postalCode: data.postalCode,
      streetName: data.streetName,
      streetNumber: data.streetNumber,
    },
  ];

  // Join everything into the final B2C object
  const mappedData = {
    ...filteredFormData,
    addresses,
  };

  return mappedData;
}
