export const companyDescription = [
  {
    value: '2000',
    name: 'Działalność handlowa (branże spożywcze)',
  },
  {
    value: '1000',
    name: 'Gastronomia',
  },
  {
    value: '3000',
    name: 'Pozostały handel, usługi, urzędy, instytucje',
  },
];

export const companyFunctions = {
  '2000': [
    {
      value: '2207',
      name: 'Handel detaliczny art. spożywcze, delikatesy',
    },
    {
      value: '2101',
      name: 'Handel hurtowy art. spożywcze',
    },
    {
      value: '2009',
      name: 'Handel detaliczny, sklepy sieciowe',
    },
    {
      value: '2112',
      name: 'Handel hurtowy dla gastronomii',
    },
    {
      value: '2013',
      name: 'Handel owocami i warzywami',
    },
    {
      value: '2110',
      name: 'Piekarnie, cukiernie',
    },
    {
      value: '2010',
      name: 'Sklepy mięsno wędliniarskie',
    },
    {
      value: '2111',
      name: 'Rzeźnie, mięso hurt',
    },
    {
      value: '2024',
      name: 'Sklepy rybne',
    },
    {
      value: '2021',
      name: 'Chemia,kosmetyki, drogerie, perfumerie',
    },
    {
      value: '2014',
      name: 'Zdrowa żywność',
    },
    {
      value: '2015',
      name: 'Sklepy monopolowe',
    },
    {
      value: '2206',
      name: 'Sklepy spożywczo-przemysłowe',
    },
    {
      value: '2220',
      name: 'Sklepy na stacjach benzynowych',
    },
    {
      value: '2903',
      name: 'Pomoc humanitarna',
    },
    {
      value: '2902',
      name: 'Sklepy i kioski na bazarach i targowiskach i poza nimi',
    },
  ],
  '1000': [
    {
      value: '1025',
      name: 'Bistra',
    },
    {
      value: '1108',
      name: 'Bary',
    },
    {
      value: '1104',
      name: 'Dyskoteki / Lokale taneczne / Kluby nocne',
    },
    {
      value: '1023',
      name: 'Pizzerie',
    },
    {
      value: '1207',
      name: 'Pensjonaty / Hotele / Sieci hoteli',
    },
    {
      value: '1202',
      name: 'Ośrodki wypoczynkowe / szkoleniowe / wakacyjne',
    },
    {
      value: '1107',
      name: 'Kina / teatry / Sceny',
    },
    {
      value: '1501',
      name: 'Fast food',
    },
    {
      value: '1026',
      name: 'Kawiarnie',
    },
    {
      value: '1301',
      name: 'Duże kuchnie i kantyny',
    },
    {
      value: '1305',
      name: 'Usługi cateringowe',
    },
    {
      value: '1502',
      name: 'Zakłady piekarnicze',
    },
    {
      value: '1008',
      name: 'Restauracje włoskie',
    },
    {
      value: '1032',
      name: 'Restauracje polskie',
    },
    {
      value: '1016',
      name: 'Restauracje azjatyckie',
    },
    {
      value: '1002',
      name: 'Restauracje arabskie',
    },
    {
      value: '1014',
      name: 'Restauracje tureckie',
    },
    {
      value: '1009',
      name: 'Restauracje japońskie',
    },
    {
      value: '1021',
      name: 'Restrauracje wegańskie',
    },
    {
      value: '1012',
      name: 'Restauracje południowoamerykańskie',
    },
    {
      value: '1010',
      name: 'Restauracje północnoamerykańskie',
    },
    {
      value: '1017',
      name: 'Restauracje - pozostałe',
    },
  ],
  '3000': [
    {
      value: '3316',
      name: 'Rzemiosło',
    },
    {
      value: '3004',
      name: 'Autohandel / Dealerzy / Akcesoria samochodowe',
    },
    {
      value: '3027',
      name: 'Sklepy przemysłowe',
    },
    {
      value: '3021',
      name: 'Tekstylia, odzież, obuwie',
    },
    {
      value: '3020',
      name: 'Artykuły sportowe i rekreacyjne',
    },
    {
      value: '3008',
      name: 'Artykuły biurowe',
    },
    {
      value: '3010',
      name: 'Elektronika użytkowa',
    },
    {
      value: '3003',
      name: 'Apteki',
    },
    {
      value: '3006',
      name: 'Kwiaciarnie / Ogrodnictwo',
    },
    {
      value: '3901',
      name: 'Inne małe przedsiębiorstwa',
    },
    {
      value: '3318',
      name: 'Salony fryzjerskie i kosmetyczne',
    },
    {
      value: '3821',
      name: 'Pralnie chemiczne',
    },
    {
      value: '3704',
      name: 'Przedsiębiorstwa transportowe i spedycyjne',
    },
    {
      value: '3505',
      name: 'Praktyki lekarskie',
    },
    {
      value: '3110',
      name: 'Szkoły, przedszkola, szpitale',
    },
    {
      value: '3609',
      name: 'Kancelarie prawne i notarialne',
    },
    {
      value: '3605',
      name: 'Doradztwo',
    },
    {
      value: '3602',
      name: 'Banki, kantory, ubezpieczenia',
    },
    {
      value: '3128',
      name: 'Placówki dyplomatyczne, ambasady, konsulaty',
    },
    {
      value: '3125',
      name: 'Urzędy',
    },
    {
      value: '3123',
      name: 'Stowarzyszenia i organizacje',
    },
    {
      value: '3105',
      name: 'Kościoły',
    },
    {
      value: '3107',
      name: 'Pozostałe',
    },
  ],
};
