'use client';

import React from 'react';
import Registration from 'components/commercetools-ui/organisms/authentication/registration';
import { RegistrationContent } from 'components/commercetools-ui/organisms/authentication/registration/types';
import { TasticProps } from 'frontastic/tastics/types';

const RegistrationTastic = ({ data }: TasticProps<RegistrationContent>) => {
  return <Registration studio={data} />;
};

export default RegistrationTastic;
