import { useEffect, useState } from 'react';
import Container from 'components/commercetools-ui/atoms/container';
import Link from 'components/commercetools-ui/atoms/link';
import ThumbsUpIcon from 'components/icons/ThumbsUpIcon';
import Steps from './steps';
import { DataB2B, DataB2C, Form, RegistrationContent } from './types';

export interface RegistrationProps {
  studio: RegistrationContent;
}

const Registration: React.FC<RegistrationProps> = ({ studio }) => {
  const [data, setData] = useState({} as DataB2B | DataB2C);
  const [complete, setComplete] = useState(false);
  const [isError, setIsError] = useState(false);

  // Enable child components to manipulate form data
  const form: Form = {
    completeState: {
      value: complete,
      set: setComplete,
    },
    dataState: {
      value: data,
      set: setData,
    },
    submitError: {
      value: isError,
      set: setIsError,
    },
  };

  useEffect(() => {
    if (complete) window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [complete]);

  return (
    <div className="bg-background py-32 lg:py-40">
      <Container>
        {/* Steps */}
        {!complete && <Steps form={form} studio={studio} />}

        {/* Thank You screen */}
        {complete && (
          <div className="mx-20 flex flex-col items-center justify-center bg-base-accent-3 py-32 lg:mx-46">
            <span className="text-base-accent-1">
              <ThumbsUpIcon scale={3.5} />
            </span>
            <p className="text-40 font-bold">{studio.thankYouScreenTitle}</p>
            <p className="text-20">{studio.thankYouScreenSubtitle}</p>
            <p className="mt-40 text-20">{studio.thankYouScreenDescription}</p>
            <Link link={studio.thankYouScreenCtaButtonUrl} variant="button-primary" className="mt-32">
              {studio.thankYouScreenCtaButtonLabel}
            </Link>
          </div>
        )}
      </Container>
    </div>
  );
};

export default Registration;
