import { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import Button from 'components/commercetools-ui/atoms/button';
import Checkbox from 'components/commercetools-ui/atoms/checkbox';
import Input from 'components/commercetools-ui/atoms/input';
import InputPassword from 'components/commercetools-ui/atoms/input-password';
import Link from 'components/commercetools-ui/atoms/link';
import ArrowRightIcon from 'components/icons/ArrowRightIcon';
import AsteriskIcon from 'components/icons/AsteriskIcon';
import CheckmarkIcon from 'components/icons/CheckmarkIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import useYupValidation from 'helpers/hooks/useYupValidation';
import { B2BStep3, DataB2B, Step, CheckboxEvent } from '../types';

const B2BStep3 = ({ form, studio, onClickSubmit }: Step) => {
  const s = studio as B2BStep3;
  const formData = form.dataState.value as DataB2B;

  const [isValid, setValid] = useState(false);

  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });

  const requiredError = formatErrorMessage({ id: 'form.required', defaultMessage: 'The field is required' });
  const emailError = formatErrorMessage({ id: 'form.email', defaultMessage: 'Email is invalid' });
  const passwordError = formatErrorMessage({
    id: 'form.password',
    defaultMessage:
      'Password must contain between 8 and 64 characters and  must contain at least 3 of the following elements: lowercase letter, capital letter, digit, special character',
  });
  const passwordConfirmationError = formatErrorMessage({
    id: 'form.passwordConfirmation',
    defaultMessage: 'Passwords do not match',
  });
  const termsConditionsError = formatErrorMessage({
    id: 'form.termsConditions',
    defaultMessage: 'You must accept the terms and conditions',
  });
  const phoneNumberError = formatErrorMessage({
    id: 'form.phoneNumber',
    defaultMessage: 'Phone number is not valid',
  });
  const isLegalRepresentativeError = formatErrorMessage({
    id: 'form.isLegalRepresentative',
    defaultMessage: 'You must confirm that you are the legal representative of the company',
  });

  const { fieldValidation, formValidation } = useYupValidation();

  const schema = useMemo(() => {
    return yup.object().shape({
      firstName: yup.string().trim().required(requiredError),
      lastName: yup.string().trim().required(requiredError),
      phone: yup.string().matches(/^\d{9}$/, { message: phoneNumberError, excludeEmptyString: true }),
      email: yup.string().required(requiredError).email(emailError),
      password: yup
        .string()
        .required(requiredError)
        .matches(
          /^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])|(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&\/=?_.,:;\\-])|(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%&\/=?_.,:;\\-])|(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&\/=?_.,:;\\-])).{8,64}$/,
          passwordError,
        ),
      passwordConfirmation: yup
        .string()
        .required(requiredError)
        .oneOf([yup.ref('password'), null], passwordConfirmationError),
      termsConditionsSelgrosAccepted: yup.boolean().required(requiredError).oneOf([true], termsConditionsError),
      termsConditionsTransgourmetAccepted: yup.boolean().required(requiredError).oneOf([true], termsConditionsError),
      isLegalRepresentative: yup.boolean().required(requiredError).oneOf([true], isLegalRepresentativeError),
    });
  }, [
    requiredError,
    phoneNumberError,
    emailError,
    passwordError,
    passwordConfirmationError,
    termsConditionsError,
    isLegalRepresentativeError,
  ]);

  const validateFirstName = useCallback(
    (value: string) => {
      return fieldValidation(schema, formData, 'firstName', value);
    },
    [fieldValidation, schema, formData],
  );

  const validateLastName = useCallback(
    (value: string) => {
      return fieldValidation(schema, formData, 'lastName', value);
    },
    [fieldValidation, schema, formData],
  );

  const validatePhone = useCallback(
    (value: string) => {
      return fieldValidation(schema, formData, 'phone', value);
    },
    [fieldValidation, schema, formData],
  );

  const validateEmail = useCallback(
    (value: string) => {
      return fieldValidation(schema, formData, 'email', value);
    },
    [fieldValidation, schema, formData],
  );

  const validatePassword = useCallback(
    (value: string) => {
      return fieldValidation(schema, formData, 'password', value);
    },
    [fieldValidation, schema, formData],
  );

  const validatePasswordConfirmation = useCallback(
    (value: string) => {
      return fieldValidation(schema, formData, 'passwordConfirmation', value);
    },
    [fieldValidation, schema, formData],
  );

  useEffect(() => {
    setValid(formValidation(schema, formData));
  }, [formData, formValidation, schema]);

  const handleDataChange = (e: React.ChangeEvent<HTMLInputElement> | CheckboxEvent) => {
    if (e.hasOwnProperty('checked')) {
      // Checkbox field event
      const ev = e as CheckboxEvent;
      const newData = { ...form.dataState.value, [ev.name]: ev.checked };
      form.dataState.set(newData);
    } else {
      // Input field event
      const ev = e as React.ChangeEvent<HTMLInputElement>;

      if (ev.target.name === 'phone' && ev.target.value.length === 0) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { phone, ...newData } = form.dataState.value;
        form.dataState.set(newData);
      } else {
        const newData = { ...form.dataState.value, [ev.target.name]: ev.target.value };
        form.dataState.set(newData);
      }
    }
  };

  const passwordTips = [
    s.b2bStep3PasswordTipsBoxTip1Description,
    s.b2bStep3PasswordTipsBoxTip2Description,
    s.b2bStep3PasswordTipsBoxTip3Description,
    s.b2bStep3PasswordTipsBoxTip4Description,
  ];

  const declarations = [
    {
      checkboxName: 'termsConditionsTransgourmetAccepted',
      text: s.b2bStep3TermsAndConditionsTransgourmet[0].b2bStep3TermsAndConditionsTransgourmetDescription,
      links: [
        {
          label: s.b2bStep3TermsAndConditionsTransgourmet[0].b2bStep3TermsAndConditionsTransgourmetLink1Label,
          url: s.b2bStep3TermsAndConditionsTransgourmet[0].b2bStep3TermsAndConditionsTransgourmetLink1Url,
        },
        {
          label: s.b2bStep3TermsAndConditionsTransgourmet[0].b2bStep3TermsAndConditionsTransgourmetLink2Label,
          url: s.b2bStep3TermsAndConditionsTransgourmet[0].b2bStep3TermsAndConditionsTransgourmetLink2Url,
        },
      ],
    },
    {
      checkboxName: 'termsConditionsSelgrosAccepted',
      text: s.b2bStep3TermsAndConditionsSelgros[0].b2bStep3TermsAndConditionsSelgrosDescription,
      links: [
        {
          label: s.b2bStep3TermsAndConditionsSelgros[0].b2bStep3TermsAndConditionsSelgrosLink1Label,
          url: s.b2bStep3TermsAndConditionsSelgros[0].b2bStep3TermsAndConditionsSelgrosLink1Url,
        },
        // {
        //   label: s.b2bStep3TermsAndConditionsSelgros[0].b2bStep3TermsAndConditionsSelgrosLink2Label,
        //   url: s.b2bStep3TermsAndConditionsSelgros[0].b2bStep3TermsAndConditionsSelgrosLink2Url,
        // },
      ],
    },
  ];

  const communicationConsents = [
    {
      checkboxName: 'emailMarketingAccepted',
      text: s.b2bStep3CommercialCommunicationSectionEmailDescription,
    },
    {
      checkboxName: 'smsMarketingAccepted',
      text: s.b2bStep3CommercialCommunicationSectionSmsDescription,
    },
  ];

  return (
    <div>
      {/* Personal information */}
      <div className="flex flex-col gap-20">
        {/* First name, last name */}
        <div className="flex gap-24 max-lg:flex-col lg:gap-12">
          <Input
            name="firstName"
            type="text"
            label={s.b2bStep3FirstNameFieldLabel}
            required
            value={(formData['firstName'] as DataB2B['firstName']) ?? ''}
            onChange={handleDataChange}
            wrapperClassName="lg:w-1/2"
            validationSchema={validateFirstName}
          />
          <Input
            name="lastName"
            type="text"
            label={s.b2bStep3LastNameFieldLabel}
            required
            value={(formData['lastName'] as DataB2B['lastName']) ?? ''}
            onChange={handleDataChange}
            wrapperClassName="lg:w-1/2"
            validationSchema={validateLastName}
          />
        </div>
        {/* Phone number */}
        <div>
          <Input
            name="phone"
            type="text"
            label={s.b2bStep3PhoneFieldLabel}
            value={(formData['phone'] as DataB2B['phone']) ?? ''}
            onChange={handleDataChange}
            validationSchema={validatePhone}
            placeholder="999999999"
          />
        </div>
      </div>

      <span className="h-1 w-full"></span>

      {/* Login credentials */}
      <div className="mt-32 flex flex-col gap-20 border-t border-t-neutral-2 pt-32">
        <Input
          name="email"
          type="text"
          label={s.b2bStep3EmailFieldLabel}
          required
          value={(formData['email'] as DataB2B['email']) ?? ''}
          onChange={handleDataChange}
          validationSchema={validateEmail}
        />
        <InputPassword
          name="password"
          label={s.b2bStep3PasswordFieldLabel}
          required
          value={(formData['password'] as DataB2B['password']) ?? ''}
          onChange={handleDataChange}
          validationSchema={validatePassword}
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
          onCopy={(e) => {
            e.preventDefault();
            return false;
          }}
        />
        <InputPassword
          name="passwordConfirmation"
          label={s.b2bStep3PasswordConfirmationFieldLabel}
          required
          value={(formData['passwordConfirmation'] as DataB2B['passwordConfirmation']) ?? ''}
          onChange={handleDataChange}
          validationSchema={validatePasswordConfirmation}
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
          onCopy={(e) => {
            e.preventDefault();
            return false;
          }}
        />

        {/* Password tips box */}
        <div className="bg-base-accent-3 p-24">
          {passwordTips.map((text, i) => {
            return (
              <div key={i} className="mt-12 flex items-center gap-12 first:mt-0">
                <span className="h-24 w-24 rounded-full bg-base-accent-1 text-neutral-5">
                  <CheckmarkIcon />
                </span>
                <p>{text}</p>
              </div>
            );
          })}
        </div>
      </div>

      {/* Marketing consents */}
      <div className="mt-32 flex flex-col gap-24 border-t border-t-neutral-2 pt-32 lg:gap-32">
        {/* Title and description */}
        <div className="font-bold">
          <p className="text-18">{s.b2bStep3MarketingConsentsSectionTitle}</p>
          <p className="mt-10">{s.b2bStep3MarketingConsentsSectionDescription}</p>
        </div>

        {/* Declarations box */}
        <div className="bg-base-accent-3 p-20">
          {declarations.map(({ text, links, checkboxName }, i) => {
            return (
              <div key={i} className="mt-20 first:mt-0">
                {/* Declaration */}
                <label className="mt-12 flex items-start gap-12 first:mt-0">
                  <Checkbox
                    containerClassName="mt-2"
                    name={checkboxName}
                    checked={(formData[checkboxName as keyof DataB2B] as boolean) ?? ''}
                    onChange={handleDataChange}
                  />
                  <span>
                    {text}{' '}
                    <span className="text-neutral-3 [&>svg]:-mt-12 [&>svg]:inline">
                      <AsteriskIcon scale={0.4} />
                    </span>
                  </span>
                </label>
                {/* Links */}
                {links.length > 0 && (
                  <div className="mt-12">
                    {links.map(({ label, url }, i) => {
                      return (
                        <div key={i} className="ml-32 mt-8 first:mt-0">
                          <Link link={url} className="inline-flex gap-4">
                            <span className="shrink-0">
                              <ArrowRightIcon />
                            </span>
                            <span>{label}</span>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>

        {/* Personal data consent declaration */}
        {/* <label className="flex items-start gap-12">
          <Checkbox
            containerClassName="mt-2"
            name="dataProcessingAccepted"
            checked={(formData['dataProcessingAccepted'] as DataB2B['dataProcessingAccepted']) ?? ''}
            onChange={handleDataChange}
          />
          <p>{s.b2bStep3PersonalDataConsentDescription}</p>
        </label> */}

        {/* Communication consents */}
        <div className="flex flex-col gap-24">
          {/* Title */}
          <p className="font-bold">{s.b2bStep3CommercialCommunicationSectionTitle}</p>

          {/* Consents */}
          {communicationConsents.map(({ text, checkboxName }, i) => {
            return (
              <label key={i} className="mt-12 flex items-start gap-12 first:mt-0">
                <Checkbox
                  containerClassName="mt-2"
                  name={checkboxName}
                  checked={(formData[checkboxName as keyof DataB2B] as boolean) ?? ''}
                  onChange={handleDataChange}
                />
                <p>{text}</p>
              </label>
            );
          })}
        </div>
      </div>

      {/* CTA divider line */}
      <div className="mt-32 h-1 border-t border-t-neutral-2"></div>

      {/* Legal company authorization */}
      {s.b2bStep3LegalCompanyAuthorizationDescription ? (
        <div className="mt-32 flex bg-base-accent-3 p-20 first:mt-0">
          <label className="flex items-start gap-12">
            <Checkbox
              containerClassName="mt-2"
              name="isLegalRepresentative"
              checked={(formData['isLegalRepresentative' as keyof DataB2B] as boolean) ?? ''}
              onChange={handleDataChange}
            />
            <p>
              {s.b2bStep3LegalCompanyAuthorizationDescription}
              <span className="text-neutral-3 [&>svg]:-mt-12 [&>svg]:inline">
                <AsteriskIcon scale={0.4} />
              </span>
            </p>
          </label>
        </div>
      ) : null}

      {/* CTA */}
      <Button onClick={() => onClickSubmit()} className="mt-20 max-sm:w-full" disabled={!isValid}>
        {s.b2bStep3CtaButtonLabel}
      </Button>
    </div>
  );
};

export default B2BStep3;
