function AsteriskIcon({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24 * scale} height={24 * scale} fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M10.5 20.75v-6.133l-5.314 3.071-1.5-2.601L9 12 3.687 8.938l1.5-2.601 5.313 3.07V3.25h3v6.158l5.313-3.071 1.5 2.601L15 12l5.313 3.086-1.5 2.602-5.313-3.07v6.132h-3z"
      ></path>
    </svg>
  );
}

export default AsteriskIcon;
