import { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import Button from 'components/commercetools-ui/atoms/button';
import Dropdown, { Option } from 'components/commercetools-ui/atoms/dropdown';
import Link from 'components/commercetools-ui/atoms/link';
import Radio from 'components/commercetools-ui/atoms/radio';
import Tooltip from 'components/commercetools-ui/atoms/tooltip';
import { companyDescription, companyFunctions } from 'helpers/data/companiesData';
import { useFormat } from 'helpers/hooks/useFormat';
import useYupValidation from 'helpers/hooks/useYupValidation';
import { usePreferredStores } from 'helpers/utils/getPreferredStores';
import ErrorFeedback from '../errorFeedback';
import { B2BStep2, CardDeliveryOption, DataB2B, DropdownSelectEvent, RadioEvent, Step } from '../types';

export type CompanyDescriptionEnum = '2000' | '1000' | '3000';

const B2BStep2 = ({ form, studio, onClickSubmit }: Step) => {
  const s = studio as B2BStep2;
  const formData = form.dataState.value as DataB2B;

  const [visibleTooltip, setVisibleTooltip] = useState('');
  const [isValid, setValid] = useState(false);

  const { preferredStores, errorFetchingStores } = usePreferredStores();

  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });

  const requiredError = formatErrorMessage({ id: 'form.required', defaultMessage: 'The field is required' });

  const { formValidation } = useYupValidation();

  const schema = useMemo(() => {
    return yup.object().shape({
      lineOfBusiness: yup.string().trim().required(requiredError),
      preferredStore: yup.string().trim().required(requiredError),
      cardDeliveryOption: yup.string().trim().required(requiredError),
    });
  }, [requiredError]);

  useEffect(() => {
    setValid(formValidation(schema, formData));
  }, [formData, formValidation, schema]);

  const handleDataChange = useCallback(
    (e: DropdownSelectEvent | RadioEvent) => {
      if (e.hasOwnProperty('field') && e.hasOwnProperty('option')) {
        // Dropdown select field event
        const ev = e as DropdownSelectEvent;
        const newData = { ...form.dataState.value, [ev.field]: ev.option.value };
        form.dataState.set(newData);
      }
      //Radio button event
      else {
        const ev = e as RadioEvent;
        const newData = { ...form.dataState.value, [ev.field]: ev.value };
        form.dataState.set(newData);
      }
    },
    [form],
  );

  const [companyDescriptionOption, setCompanyDescriptionOption] = useState<Option>();
  const [companyFunctionsOptions, setCompanyFunctionsOptions] = useState<Option[]>([
    {
      name: s.b2bStep2LineOfBusinessFieldOptionSelect,
      value: '',
    },
  ]);
  const storesUpdated = [{ name: s.b2bStep2StoreFieldOptionSelect, value: '' }, ...preferredStores];
  const companyDescriptionUpdated = [
    { name: s.b2bStep2CompanyDescriptionFieldOptionSelect, value: '' },
    ...companyDescription,
  ];

  const radioOptionsCardDelivery = useMemo(() => {
    return [
      {
        optionName: s.b2bStep2CardDeliveryOptionStoreLabel,
        optionDescription: s.b2bStep2CardDeliveryOptionStoreDescription,
        value: 'store',
      },
      {
        optionName: s.b2bStep2CardDeliveryOptionPostLabel,
        optionDescription: s.b2bStep2CardDeliveryOptionPostDescription,
        value: 'post',
      },
    ] as CardDeliveryOption[];
  }, [
    s.b2bStep2CardDeliveryOptionPostDescription,
    s.b2bStep2CardDeliveryOptionPostLabel,
    s.b2bStep2CardDeliveryOptionStoreDescription,
    s.b2bStep2CardDeliveryOptionStoreLabel,
  ]);

  useEffect(() => {
    if (!formData.cardDeliveryOption) {
      const ev = { field: 'cardDeliveryOption', value: radioOptionsCardDelivery[0].value } as RadioEvent;
      handleDataChange(ev);
    }
  }, [formData.cardDeliveryOption, radioOptionsCardDelivery, handleDataChange]);

  //save company description and company functions options to state
  useEffect(() => {
    const companyDescriptionValue = Object.keys(companyFunctions).find((key) => {
      return companyFunctions[key as CompanyDescriptionEnum].find((option) => option.value === formData.lineOfBusiness);
    });
    const companyDescriptionOption = companyDescription.find((option) => option.value === companyDescriptionValue);
    setCompanyDescriptionOption(companyDescriptionOption);
    if (companyDescriptionOption) {
      const companyFunctionOption = companyFunctions[companyDescriptionOption.value as CompanyDescriptionEnum];
      setCompanyFunctionsOptions(companyFunctionOption);
    }
  }, [formData.lineOfBusiness, companyFunctionsOptions]);

  const toggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const ev = { field: 'cardDeliveryOption', value: e.target.value } as RadioEvent;
    handleDataChange(ev);
  };

  // On change preferred store
  const selectOnChangePreferredStore = (option: Option) => {
    const ev = { field: 'preferredStore', option: option } as DropdownSelectEvent;
    handleDataChange(ev);
  };

  // On change company description
  const handleCompanyDescriptionChange = (option: Option) => {
    setCompanyDescriptionOption(option);
    if (option.value === '') {
      const defaultOption = [
        {
          name: s.b2bStep2LineOfBusinessFieldOptionSelect,
          value: '',
        },
      ];
      setCompanyFunctionsOptions(defaultOption);
      handleLineOfBusinessChange(defaultOption[0]);
      return;
    }
    const companyFunctionsOptions = companyFunctions[option.value as CompanyDescriptionEnum];
    setCompanyFunctionsOptions(companyFunctionsOptions);
    handleLineOfBusinessChange(companyFunctionsOptions[0]);
  };

  // On change company line of business
  const handleLineOfBusinessChange = (option: Option) => {
    const ev = { field: 'lineOfBusiness', option: option } as DropdownSelectEvent;
    handleDataChange(ev);
  };

  //Select default value store
  const selectDefaultValueStore = storesUpdated.find((option) => {
    if (formData.preferredStore !== undefined) {
      return option.value === formData.preferredStore;
    } else {
      return option.value === '';
    }
  });

  //Select default value line of business
  const selectDefaultLineOfBusiness = companyFunctionsOptions.find((option) => {
    if (formData.lineOfBusiness !== undefined) {
      return option.value === formData.lineOfBusiness;
    } else {
      return option.value === '';
    }
  });

  return (
    <div className="flex flex-col gap-32">
      <div className="flex flex-col gap-20">
        {/* Information about company */}
        <p className="text-18 font-bold">{s.b2bStep2CompanyIntroSectionTitle}</p>

        {/* Add company description */}
        <div className="flex flex-col lg:lg:w-[456px]">
          <Dropdown
            className="w-full"
            name="companyDescription"
            required
            selectDefaultValue={companyDescriptionOption}
            selectOptions={companyDescriptionUpdated}
            defaultValue={undefined}
            selectOnChange={handleCompanyDescriptionChange}
            label={s.b2bStep2CompanyDescriptionLabel}
          />
        </div>

        {/* Line of business */}
        {companyDescriptionOption?.value && (
          <div className="flex flex-col lg:w-[456px]">
            <Dropdown
              className="w-full"
              name="lineOfBusiness"
              required
              selectDefaultValue={selectDefaultLineOfBusiness}
              selectOptions={companyFunctionsOptions}
              defaultValue={undefined}
              selectOnChange={handleLineOfBusinessChange}
              label={s.b2bStep2LineOfBusinessLabel}
            />
          </div>
        )}
      </div>

      {/* Choose your preferred store */}
      <div className="">
        <p className="text-18 font-bold">{s.b2bStep2StoreIntroSectionTitle}</p>
        <p className="pt-12 text-16 leading-6">{s.b2bStep2StoreIntroSectionDescription}</p>
        <Link link={s.b2bStep2LinkToStores[0].link} className="text-16 leading-6 underline">
          {s.b2bStep2LinkToStores[0].name}
        </Link>
        {/* Dropdown to choose prefrred store */}
        <div className="flex w-full flex-col pt-24 lg:w-[456px]">
          <Dropdown
            className="w-full"
            name="preferredStore"
            label={formatMessage({ id: 'choose.option', defaultMessage: 'Choose option' })}
            required
            selectDefaultValue={selectDefaultValueStore}
            selectOptions={storesUpdated}
            defaultValue={undefined}
            selectOnChange={selectOnChangePreferredStore}
          />
        </div>
      </div>

      {/* Options of Card Delivery */}
      <div className="">
        <p className="text-18 font-bold">{s.b2bStep2CardDeliveryIntroSectionTitle}</p>
        <p className="pt-12 text-16 leading-6">{s.b2bStep2CardDeliveryIntroSectionDescription}</p>

        {/* Options of Card Delivery */}
        <div className="flex flex-col gap-16 pt-24">
          {radioOptionsCardDelivery.map((option) => (
            <div key={option.optionName} className="flex flex-col gap-12">
              <Radio
                value={option.value}
                name="cardDeliveryOption"
                checked={formData.cardDeliveryOption === option.value}
                onChecked={toggle}
                required
              >
                <div className="flex items-center gap-4">
                  <p className="text-16 leading-6">{option.optionName}</p>
                  <Tooltip
                    key={`${option.optionDescription} tooltip`}
                    message={option.optionDescription}
                    show={visibleTooltip === option.optionName}
                    setShow={(show) => setVisibleTooltip(show ? option.optionName : '')}
                  />
                </div>
              </Radio>
            </div>
          ))}
        </div>
        {/* Submit button */}
      </div>
      <div className="flex">
        <Button onClick={() => onClickSubmit()} className="max-sm:w-full" disabled={!isValid}>
          {s.b2bStep2CtaButtonLabel}
        </Button>
      </div>
      {/* Error message */}
      {errorFetchingStores && <ErrorFeedback type="errorFetchingStores" />}
    </div>
  );
};

export default B2BStep2;
