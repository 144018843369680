function ThumbsUpIcon({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24 * scale} height={24 * scale} fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M17.73 20.5H7.213v-12l6.634-6.596.846.846c.104.104.19.242.259.413.069.171.103.332.103.484V3.9l-1.062 4.6h6.7c.476 0 .896.183 1.26.548.366.364.548.784.548 1.26v1.616c0 .103-.014.216-.04.336-.027.12-.058.233-.09.337l-2.866 6.761c-.144.32-.384.591-.721.812-.338.22-.688.33-1.052.33zM8.712 19h9.02c.07 0 .142-.019.216-.057a.372.372 0 00.168-.193L21 12v-1.692a.3.3 0 00-.087-.221.3.3 0 00-.22-.087h-8.597l1.254-5.48-4.639 4.619V19zm-1.5-10.5V10H4v9h3.212v1.5H2.5v-12h4.712z"
      ></path>
    </svg>
  );
}

export default ThumbsUpIcon;
