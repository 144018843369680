import { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import Button from 'components/commercetools-ui/atoms/button';
import Input from 'components/commercetools-ui/atoms/input';
import { useFormat } from 'helpers/hooks/useFormat';
import useYupValidation from 'helpers/hooks/useYupValidation';
import CustomerTypeSelector from '../customerTypeSelector';
import { B2CStep1, DataB2C, Step1 } from '../types';

const B2CStep1 = ({ b2bState, form, studio, onClickSubmit }: Step1) => {
  const s = studio as B2CStep1;
  const formData = form.dataState.value as DataB2C;

  const [isValid, setValid] = useState(false);

  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });

  const requiredError = formatErrorMessage({ id: 'form.required', defaultMessage: 'The field is required' });

  const postalCodeError = formatErrorMessage({
    id: 'form.postalCode',
    defaultMessage: 'The postal code must be 5 digits long',
  });
  const phoneNumberError = formatErrorMessage({
    id: 'form.phoneNumber',
    defaultMessage: 'Phone number is not valid',
  });

  const { fieldValidation, formValidation } = useYupValidation();

  const schema = useMemo(() => {
    return yup.object().shape({
      firstName: yup.string().trim().required(requiredError),
      lastName: yup.string().trim().required(requiredError),
      streetName: yup.string().trim().required(requiredError),
      streetNumber: yup.string().trim().required(requiredError),
      postalCode: yup
        .string()
        .required(requiredError)
        .matches(/^\d{2}(-)?\d{3}?$/, postalCodeError),
      city: yup.string().trim().required(requiredError),
      phone: yup.string().matches(/^\d{9}$/, { message: phoneNumberError, excludeEmptyString: true }),
    });
  }, [requiredError, postalCodeError, phoneNumberError]);

  const validateFirstName = useCallback(
    (value: string) => {
      return fieldValidation(schema, formData, 'firstName', value);
    },
    [fieldValidation, schema, formData],
  );

  const validateLastName = useCallback(
    (value: string) => {
      return fieldValidation(schema, formData, 'lastName', value);
    },
    [fieldValidation, schema, formData],
  );

  const validateStreetName = useCallback(
    (value: string) => {
      return fieldValidation(schema, formData, 'streetName', value);
    },
    [fieldValidation, schema, formData],
  );

  const validateStreetNumber = useCallback(
    (value: string) => {
      return fieldValidation(schema, formData, 'streetNumber', value);
    },
    [fieldValidation, schema, formData],
  );

  const validatePostalCode = useCallback(
    (value: string) => {
      return fieldValidation(schema, formData, 'postalCode', value);
    },
    [fieldValidation, schema, formData],
  );

  const validateCity = useCallback(
    (value: string) => {
      return fieldValidation(schema, formData, 'city', value);
    },
    [fieldValidation, schema, formData],
  );

  const validatePhone = useCallback(
    (value: string) => {
      return fieldValidation(schema, formData, 'phone', value);
    },
    [fieldValidation, schema, formData],
  );

  useEffect(() => {
    setValid(formValidation(schema, formData));
  }, [formData, formValidation, schema]);

  const handleDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'phone' && e.target.value.length === 0) {
      const { phone, ...newData } = form.dataState.value;
      form.dataState.set(newData);
    } else {
      const newData = { ...form.dataState.value, [e.target.name]: e.target.value };
      form.dataState.set(newData);
    }
  };

  return (
    <div>
      {/* Intro explanation */}
      <div>
        <p className="text-18 font-bold">{s.b2cStep1IntroSectionTitle}</p>
        <p className="pt-12">{s.b2cStep1IntroSectionDescription}</p>
      </div>

      {/* Customer type selector */}
      <div className="py-32">
        <CustomerTypeSelector
          b2bState={b2bState}
          form={form}
          b2cLabelDesktop={s.b2cStep1CustomerTypeButtonB2CDesktop}
          b2cLabelMobile={s.b2cStep1CustomerTypeButtonB2CMobile}
          b2bLabelDesktop={s.b2cStep1CustomerTypeButtonB2BDesktop}
          b2bLabelMobile={s.b2cStep1CustomerTypeButtonB2BMobile}
        />
      </div>

      {/* Form fields */}

      <div className="flex flex-col gap-20">
        {/* First name + last name */}
        <div className="flex gap-24 max-lg:flex-col lg:gap-12">
          <Input
            name="firstName"
            type="text"
            label={s.b2cStep1FirstNameFieldLabel}
            required
            value={(formData['firstName'] as DataB2C['firstName']) ?? ''}
            onChange={handleDataChange}
            wrapperClassName="lg:w-1/2"
            validationSchema={validateFirstName}
          />
          <Input
            name="lastName"
            type="text"
            label={s.b2cStep1LastNameFieldLabel}
            required
            value={(formData['lastName'] as DataB2C['lastName']) ?? ''}
            onChange={handleDataChange}
            wrapperClassName="lg:w-1/2"
            validationSchema={validateLastName}
          />
        </div>

        {/* Street + number */}
        <div className="flex gap-24 max-lg:flex-col lg:gap-12">
          <Input
            name="streetName"
            type="text"
            label={s.b2cStep1StreetNameFieldLabel}
            required
            value={(formData['streetName'] as DataB2C['streetName']) ?? ''}
            onChange={handleDataChange}
            wrapperClassName="lg:w-4/5"
            validationSchema={validateStreetName}
          />
          <Input
            name="streetNumber"
            type="text"
            label={s.b2cStep1StreetNumberFieldLabel}
            required
            value={(formData['streetNumber'] as DataB2C['streetNumber']) ?? ''}
            onChange={handleDataChange}
            wrapperClassName="lg:w-1/5"
            validationSchema={validateStreetNumber}
          />
        </div>

        {/* Zipcode + town */}
        <div className="flex gap-24 max-lg:flex-col lg:gap-12">
          <Input
            name="postalCode"
            type="text"
            label={s.b2cStep1ZipcodeFieldLabel}
            required
            value={(formData['postalCode'] as DataB2C['postalCode']) ?? ''}
            onChange={handleDataChange}
            wrapperClassName="lg:w-1/2"
            validationSchema={validatePostalCode}
          />
          <Input
            name="city"
            type="text"
            label={s.b2cStep1CityFieldLabel}
            required
            value={(formData['city'] as DataB2C['city']) ?? ''}
            onChange={handleDataChange}
            wrapperClassName="lg:w-1/2"
            validationSchema={validateCity}
          />
        </div>

        {/* Phone number */}
        <div>
          <Input
            name="phone"
            type="text"
            label={s.b2cStep1PhoneFieldLabel}
            value={(formData['phone'] as DataB2C['phone']) ?? ''}
            onChange={handleDataChange}
            validationSchema={validatePhone}
            placeholder="999999999"
          />
        </div>
      </div>

      {/* CTA */}
      <Button onClick={() => onClickSubmit()} disabled={!isValid} className="mt-32 max-sm:w-full">
        {s.b2cStep1CtaButtonLabel}
      </Button>
    </div>
  );
};

export default B2CStep1;
