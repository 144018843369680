import Button from 'components/commercetools-ui/atoms/button';
import BuildingIcon from 'components/icons/BuildingIcon';
import UserIcon from 'components/icons/UserIcon';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import { B2BState, DataB2B, DataB2C, Form } from './types';

interface Props {
  b2bState: B2BState;
  form: Form;
  b2cLabelDesktop: string;
  b2cLabelMobile: string;
  b2bLabelDesktop: string;
  b2bLabelMobile: string;
}

const CustomerTypeSelector = ({
  b2bState,
  form,
  b2cLabelMobile,
  b2cLabelDesktop,
  b2bLabelMobile,
  b2bLabelDesktop,
}: Props) => {
  const b2b = b2bState.value === true;
  const b2c = b2bState.value === false;
  const baseButtonClassName = 'flex-1';
  const activeButtonClassName = '!bg-base-accent-3 !border-base-accent-1';
  const b2cButtonClassName = baseButtonClassName + ' ' + (b2c ? activeButtonClassName : '');
  const b2bButtonClassName = baseButtonClassName + ' ' + (b2b ? activeButtonClassName : '');

  const handleClick = (b2b: boolean) => {
    b2bState.set(b2b);
    form.dataState.set({} as DataB2B | DataB2C);
  };

  const [isAboveTablet] = useMediaQuery(tablet);

  return (
    <div className="flex w-full gap-12">
      <Button
        variant="registration"
        className={b2cButtonClassName}
        icon={<UserIcon />}
        iconPosition="left"
        onClick={() => handleClick(false)}
      >
        {isAboveTablet ? b2cLabelDesktop : b2cLabelMobile}
      </Button>
      <Button
        variant="registration"
        className={b2bButtonClassName}
        icon={<BuildingIcon />}
        iconPosition="left"
        onClick={() => handleClick(true)}
      >
        {isAboveTablet ? b2bLabelDesktop : b2bLabelMobile}
      </Button>
    </div>
  );
};

export default CustomerTypeSelector;
