import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { AccountRegisterContactPerson } from 'shared/types/account/AccountRegisterContactPerson';
import * as yup from 'yup';
import Button from 'components/commercetools-ui/atoms/button';
import Input from 'components/commercetools-ui/atoms/input';
import TrashIcon from 'components/icons/TrashIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import useYupValidation from 'helpers/hooks/useYupValidation';
import ErrorFeedback from '../errorFeedback';
import { DataB2B, B2BStep4, Step } from '../types';

export type CompanyDescriptionEnum = '2000' | '1000' | '3000';

const B2BStep4 = ({ form, studio, onClickSubmit }: Step) => {
  const s = studio as B2BStep4;
  const formData = form.dataState.value as DataB2B;

  const [employeeList, setEmployeeList] = useState<Array<AccountRegisterContactPerson>>([]);
  const [isValid, setValid] = useState(false);
  const [isDisabledAddEmployee, setIsDisabledAddEmployee] = useState(false);

  const { formatMessage } = useFormat({ name: 'account' });
  const tooltipWhenButtonDisabled = formatMessage({
    id: 'registration.addEmployee',
    defaultMessage: "It's not possible to add more that 5 employees",
  });

  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });

  const requiredError = formatErrorMessage({ id: 'form.required', defaultMessage: 'The field is required' });
  const emailError = formatErrorMessage({ id: 'form.email', defaultMessage: 'Email is invalid' });

  const { fieldValidation, formValidation } = useYupValidation();

  const schema = useMemo(() => {
    return yup.object().shape({
      firstName: yup.string().trim().required(requiredError),
      lastName: yup.string().trim().required(requiredError),
      email: yup.string().required(requiredError).email(emailError),
    });
  }, [requiredError, emailError]);

  const validateFirstName = useCallback(
    (value: string) => {
      return fieldValidation(schema, formData, 'firstName', value);
    },
    [fieldValidation, schema, formData],
  );

  const validateLastName = useCallback(
    (value: string) => {
      return fieldValidation(schema, formData, 'lastName', value);
    },
    [fieldValidation, schema, formData],
  );

  const validateEmail = useCallback(
    (value: string) => {
      return fieldValidation(schema, formData, 'email', value);
    },
    [fieldValidation, schema, formData],
  );

  useEffect(() => {
    setIsDisabledAddEmployee(employeeList.length === 5);

    const invalid = employeeList.some((employee) => formValidation(schema, employee) === false);
    setValid(!invalid);
  }, [employeeList, formValidation, schema]);

  const handleAddEmployee = () => {
    const newEmployee = {
      email: '',
      firstName: '',
      lastName: '',
    };
    setEmployeeList([...employeeList, newEmployee]);
  };

  const handleRemoveEmployee = (index: number) => {
    const newEmployeeList = [...employeeList.slice(0, index), ...employeeList.slice(index + 1)];

    setEmployeeList(newEmployeeList);
    form.dataState.set({ ...formData, contactPersons: newEmployeeList });
  };

  const handleDataChange = (e: ChangeEvent<HTMLInputElement>, index: number, attribute: string) => {
    const newEmployeeList = employeeList.map((contactPerson, i) =>
      index === i ? { ...contactPerson, [attribute]: e.target.value } : contactPerson,
    );

    setEmployeeList(newEmployeeList);
    form.dataState.set({ ...formData, contactPersons: newEmployeeList });
  };

  return (
    <div className="flex flex-col gap-32">
      <div className="flex flex-col gap-20">
        {/* Account for your employees */}
        <p className="text-18 font-bold">{s.b2bStep4EmployeeIntroSectionTitle}</p>
      </div>

      {employeeList.map((employee, index) => (
        <div key={index} className="flex w-full flex-col gap-16">
          {/* Remove employee button */}
          <div className="flex items-center justify-between">
            <p className="whitespace-nowrap text-18 font-bold leading-[22.5px]">Employee #{index + 1}</p>
            <span
              onClick={() => {
                handleRemoveEmployee(index);
              }}
              className="flex items-center gap-4 text-14 leading-[17.5px] hover:cursor-pointer"
            >
              {s.b2bStep4RemoveEmployeeLabel}
              <TrashIcon />
            </span>
          </div>
          {/* Name and Last Name */}
          <div className="flex grow flex-col gap-12 md:flex-row">
            <Input
              type="text"
              name="firstName"
              value={employee.firstName}
              onChange={(e) => handleDataChange(e, index, 'firstName')}
              label={s.b2bStep4EmployeeNameFieldLabel}
              wrapperClassName="grow"
              validationSchema={validateFirstName}
            />
            <Input
              type="text"
              name="lastName"
              value={employee.lastName}
              onChange={(e) => handleDataChange(e, index, 'lastName')}
              label={s.b2bStep4EmployeeLastNameFieldLabel}
              wrapperClassName="grow"
              validationSchema={validateLastName}
            />
          </div>
          {/* Email */}
          <Input
            type="text"
            name="email"
            value={employee.email}
            onChange={(e) => handleDataChange(e, index, 'email')}
            label={s.b2bStep4EmployeeEmailAddressFieldLabel}
            validationSchema={validateEmail}
          />
        </div>
      ))}
      {/* Button to add a new employee */}
      <div className="flex">
        <Button
          onClick={handleAddEmployee}
          variant="secondary"
          className="max-sm:w-full"
          disabled={isDisabledAddEmployee}
          title={isDisabledAddEmployee ? tooltipWhenButtonDisabled : ''}
        >
          {s.b2bStep4AddEmployeeButtonLabel}
        </Button>
      </div>

      {/* CTA divider line */}
      <div className="h-1 border-t border-t-neutral-2"></div>

      {/* CTA */}
      <div className="flex">
        <Button onClick={() => onClickSubmit()} className="max-sm:w-full" disabled={!isValid}>
          {s.b2bStep4CompleteRegistrationButtonLabel}
        </Button>
      </div>

      {/* Error message */}
      {form.submitError.value && <ErrorFeedback type="errorCreatingAccount" />}
    </div>
  );
};

export default B2BStep4;
