import { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import Button from 'components/commercetools-ui/atoms/button';
import Dropdown, { Option } from 'components/commercetools-ui/atoms/dropdown';
import Link from 'components/commercetools-ui/atoms/link';
import Radio from 'components/commercetools-ui/atoms/radio';
import Tooltip from 'components/commercetools-ui/atoms/tooltip';
import { useFormat } from 'helpers/hooks/useFormat';
import useYupValidation from 'helpers/hooks/useYupValidation';
import { usePreferredStores } from 'helpers/utils/getPreferredStores';
import ErrorFeedback from '../errorFeedback';
import { DataB2C, DropdownSelectEvent, RadioEvent, Step, B2CStep2, CardDeliveryOption } from '../types';

const B2CStep2 = ({ form, studio, onClickSubmit }: Step) => {
  const s = studio as B2CStep2;
  const formData = form.dataState.value as DataB2C;

  const [isValid, setValid] = useState(false);
  const [visibleTooltip, setVisibleTooltip] = useState('');

  const { preferredStores, errorFetchingStores } = usePreferredStores();
  const storesUpdated = [{ name: s.b2cStep2StoreFieldOptionSelect, value: '' }, ...preferredStores];

  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });

  const requiredError = formatErrorMessage({ id: 'form.required', defaultMessage: 'The field is required' });

  const { formValidation } = useYupValidation();

  const schema = useMemo(() => {
    return yup.object().shape({
      preferredStore: yup.string().required(requiredError),
      cardDeliveryOption: yup.string().required(requiredError),
    });
  }, [requiredError]);

  useEffect(() => {
    setValid(formValidation(schema, formData));
  }, [formData, formValidation, schema]);

  const handleDataChange = useCallback(
    (e: DropdownSelectEvent | RadioEvent) => {
      if (e.hasOwnProperty('field') && e.hasOwnProperty('option')) {
        // Dropdown select field event
        const ev = e as DropdownSelectEvent;
        const newData = { ...form.dataState.value, [ev.field]: ev.option.value };
        form.dataState.set(newData);
      }
      //Radio button event
      else {
        const ev = e as RadioEvent;
        const newData = { ...form.dataState.value, [ev.field]: ev.value };
        form.dataState.set(newData);
      }
    },
    [form],
  );

  const radioOptionsCardDelivery = useMemo(() => {
    return [
      {
        optionName: s.b2cStep2CardDeliveryOptionStoreLabel,
        optionDescription: s.b2cStep2CardDeliveryOptionStoreDescription,
        value: 'store',
      },
      {
        optionName: s.b2cStep2CardDeliveryOptionPostLabel,
        optionDescription: s.b2cStep2CardDeliveryOptionPostDescription,
        value: 'post',
      },
    ] as CardDeliveryOption[];
  }, [
    s.b2cStep2CardDeliveryOptionPostDescription,
    s.b2cStep2CardDeliveryOptionPostLabel,
    s.b2cStep2CardDeliveryOptionStoreDescription,
    s.b2cStep2CardDeliveryOptionStoreLabel,
  ]);

  useEffect(() => {
    if (!formData.cardDeliveryOption) {
      const ev = { field: 'cardDeliveryOption', value: radioOptionsCardDelivery[0].value } as RadioEvent;
      handleDataChange(ev);
    }
  }, [radioOptionsCardDelivery, formData.cardDeliveryOption, handleDataChange]);

  const toggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const ev = { field: 'cardDeliveryOption', value: e.target.value } as RadioEvent;
    handleDataChange(ev);
  };

  const selectOnChangePreferredStore = (option: Option) => {
    const ev = { field: 'preferredStore', option: option } as DropdownSelectEvent;
    handleDataChange(ev);
  };

  const selectDefaultValueStore = storesUpdated.find((option) => {
    if (formData.preferredStore !== undefined) {
      return option.value === formData.preferredStore;
    } else {
      return option.value === '';
    }
  });

  return (
    <div className="flex flex-col gap-32">
      {/* Choose your preferred store */}
      <div className="">
        <p className="text-18 font-bold">{s.b2cStep2StoreIntroSectionTitle}</p>
        <p className="pt-12 text-16 leading-6">
          {s.b2cStep2StoreIntroSectionDescription}{' '}
          <Link link={s.b2cStep2LinkToStores[0].link} className="text-16 leading-6 underline">
            {s.b2cStep2LinkToStores[0].name}
          </Link>
        </p>
        {/* Dropdown to choose prefrred store */}
        <div className="flex w-full flex-col pt-24 lg:w-[32%]">
          <Dropdown
            className="w-full"
            name="preferredStore"
            label={formatMessage({ id: 'choose.option', defaultMessage: 'Choose option' })}
            required
            selectDefaultValue={selectDefaultValueStore}
            selectOptions={storesUpdated}
            defaultValue={undefined}
            selectOnChange={selectOnChangePreferredStore}
          />
        </div>
      </div>

      {/* Options of Card Delivery */}
      <div className="">
        <p className="text-18 font-bold">{s.b2cStep2CardDeliveryIntroSectionTitle}</p>
        <p className="pt-12 text-16 leading-6">{s.b2cStep2CardDeliveryIntroSectionDescription}</p>

        {/* Options of Card Delivery */}
        <div className="flex flex-col gap-16 pt-24">
          {radioOptionsCardDelivery.map((option, index) => (
            <div key={`${option.optionName} ${index}`} className="relative flex flex-col gap-12">
              <Radio
                value={option.value}
                name="cardDeliveryOption"
                checked={formData.cardDeliveryOption === option.value}
                onChecked={toggle}
                required
              >
                <div className="flex items-center gap-4">
                  <p className="text-16 leading-6">{option.optionName}</p>
                  <Tooltip
                    show={option.optionName === visibleTooltip}
                    setShow={(show) => setVisibleTooltip(show ? option.optionName : '')}
                    message={option.optionDescription}
                  />
                </div>
              </Radio>
            </div>
          ))}
        </div>
        {/* Submit button */}
      </div>
      <div className="flex">
        <Button onClick={() => onClickSubmit()} disabled={!isValid} className="max-sm:w-full">
          {s.b2cStep2CtaButtonLabel}
        </Button>
      </div>
      {/* Error message */}
      {errorFetchingStores && <ErrorFeedback type="errorFetchingStores" />}
    </div>
  );
};

export default B2CStep2;
