import React, { useEffect, useRef } from 'react';
import CheckmarkIcon from 'components/icons/CheckmarkIcon';
import PencilIcon from 'components/icons/PencilIcon';
import useClassNames from 'helpers/hooks/useClassNames';

interface Props {
  number: number;
  label: string;
  isExpanded: boolean;
  isCompleted: boolean;
  onEdit: () => void;
  Component: JSX.Element;
}

const Step: React.FC<Props> = ({ number, label, isExpanded, isCompleted, onEdit, Component }) => {
  const ref = useRef<HTMLDivElement>(null);

  const numberClassName = useClassNames([
    'rounded-full w-40 h-40 flex items-center justify-center border-2 font-bold',
    {
      'border-base-accent-1 bg-base-accent-1 text-neutral-5': isExpanded,
      'border-base-accent-1 bg-neutral-5 text-base-accent-1': !isExpanded,
    },
  ]);

  useEffect(() => {
    if (isExpanded && ref.current) {
      let headerBottom = 200;
      const header = document.querySelector('header');
      if (header) {
        headerBottom = header.getBoundingClientRect().bottom;
      }
      const y = ref.current.getBoundingClientRect().top + window.scrollY - headerBottom - 6;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, [isExpanded]);

  return (
    <div
      className={`shadow-custom mt-12 bg-neutral-5 px-12 first:mt-0 lg:px-32 ${
        isExpanded ? 'py-20 lg:py-32' : 'py-20'
      }`}
      ref={ref}
    >
      <div className="flex items-center justify-between">
        <div className="flex cursor-default items-center gap-12 lg:gap-16">
          {/* Number if current or future step */}
          {!isCompleted && <span className={numberClassName}>{number}</span>}

          {/* Checkmark if step is completed */}
          {isCompleted && !isExpanded && (
            <span className="flex h-40 w-40 items-center justify-center rounded-full border-2 border-base-accent-1 bg-white text-base-accent-1">
              <CheckmarkIcon />
            </span>
          )}

          {/* Step name */}
          <p className="font-bold">{label}</p>
        </div>

        {/* Edit icon */}
        {isCompleted && !isExpanded && (
          <p
            className="text-14 text-secondary-black underline decoration-secondary-black underline-offset-2 hover:cursor-pointer"
            onClick={onEdit}
          >
            <PencilIcon />
          </p>
        )}
      </div>

      {/* Panel with child component */}
      {isExpanded && <div className="mt-20 border-t border-neutral-2 pt-32">{Component}</div>}
    </div>
  );
};

export default Step;
