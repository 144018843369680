import { useEffect, useState } from 'react';
import { Channel } from 'shared/types/channel/Channel';
import { Option } from 'components/commercetools-ui/atoms/dropdown';
import { sdk } from 'sdk';

export const usePreferredStores = () => {
  const [preferredStores, setPreferredStores] = useState<Array<Option>>([]);
  const [errorFetchingStores, setErrorFetchingStores] = useState(false);

  useEffect(() => {
    const fetchChannel = async () => {
      const response = await sdk.callAction({
        actionName: 'channel/getChannels',
      });
      if (response.isError) {
        setErrorFetchingStores(true);
      } else {
        if (errorFetchingStores) setErrorFetchingStores(false);
        const channels = response.data as Channel[];
        const options = channels.map((channel) => {
          return { name: channel.name, value: channel.code } as Option;
        });
        setPreferredStores(options);
      }
    };
    fetchChannel();
  }, []);

  return { preferredStores, errorFetchingStores };
};
