import React from 'react';
import ExclamationMarkIcon from 'components/icons/ExclamationMarkIcon';
import useClassNames from 'helpers/hooks/useClassNames';
import Typography from '../typography';

interface Props extends Omit<React.ComponentProps<'input'>, 'key'> {
  className?: string;
  inputClassName?: string;
  onChecked?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isValid?: boolean;
  errorMessage?: string;
}

const Radio: React.FC<Props> = ({
  className = '',
  inputClassName = '',
  onChecked,
  children,
  checked,
  onChange,
  isValid = true,
  errorMessage,
  disabled,
  ...props
}) => {
  const labelClassName = useClassNames(['text-16 leading-5 text-neutral-4', className]);

  const inputClassNames = useClassNames([
    'h-18 w-18',
    disabled
      ? 'cursor-not-allowed bg-base-accent-3 border-base-accent-2'
      : checked
      ? 'text-base-accent-1 border-0 bg-neutral-5'
      : `text-neutral-5 border-base-accent-1 hover:bg-base-accent-1 hover:outline hover:outline-4 hover:-outline-offset-[5px] hover:outline-neutral-5 hover:cursor-pointer ${
          !isValid && 'border-status-danger-border text-neutral-5 bg-neutral-5 hover:!bg-neutral-4'
        }`,
    ,
    inputClassName,
  ]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(e);
    onChecked?.(e);
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-12">
        <input
          type="radio"
          id={props.id}
          className={inputClassNames}
          checked={checked}
          onChange={(e) => handleChange(e)}
          {...props}
          disabled={disabled}
        />

        <label className={labelClassName} htmlFor={props.id}>
          {children}
        </label>
      </div>
      {!isValid && (
        <div className="mt-12 flex items-center gap-4">
          <span className="text-status-danger-border">
            <ExclamationMarkIcon />
          </span>
          <Typography className="text-14 font-medium text-status-danger-border" as="span">
            {errorMessage}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default Radio;
