import { useState, useCallback, useMemo, useEffect } from 'react';
import * as yup from 'yup';
import Button from 'components/commercetools-ui/atoms/button';
import Input from 'components/commercetools-ui/atoms/input';
import InfoIcon from 'components/icons/InfoIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import useYupValidation from 'helpers/hooks/useYupValidation';
import { sdk } from 'sdk';
import { Nip24CompanyInfo } from '../../../../../../../types/account/Nip24CompanyInfo';
import CustomerTypeSelector from '../customerTypeSelector';
import ErrorFeedback from '../errorFeedback';
import { B2BStep1, DataB2B, Step1 } from '../types';

const B2BStep1 = ({ b2bState, form, studio, onClickSubmit }: Step1) => {
  const s = studio as B2BStep1;
  const formData = form.dataState.value as DataB2B;
  let nipData = (formData.nipData as Nip24CompanyInfo) ?? null;

  const [nipId, setNipId] = useState(nipData?.nipId ?? '');
  const [nipDataLoading, setNipDataLoading] = useState(false);
  const [disableStreetName, setDisableStreetName] = useState(true);
  const [disableStreetNumber, setDisableStreetNumber] = useState(true);
  const [disablePostalCode, setDisablePostalCode] = useState(true);
  const [disableCity, setDisableCity] = useState(true);
  const [nipError, setNipError] = useState(false);
  const [isValidNipId, setValidNipId] = useState(false);
  const [isValid, setValid] = useState(false);

  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });

  const requiredError = formatErrorMessage({ id: 'form.required', defaultMessage: 'The field is required' });
  const postalCodeError = formatErrorMessage({
    id: 'form.postalCode',
    defaultMessage: 'The postal code must be 5 digits long',
  });
  const nipNumberError = formatErrorMessage({
    id: 'form.nipNumber',
    defaultMessage: 'NIP number consists of 10 digits',
  });

  const { fieldValidation, formValidation } = useYupValidation();

  const nipIdSchema = useMemo(() => {
    return yup.object().shape({
      nipId: yup
        .string()
        .required(requiredError)
        .matches(/^\d{10}?$/, nipNumberError),
    });
  }, [requiredError, nipNumberError]);

  const schema = useMemo(() => {
    return yup.object().shape({
      companyNameLong: yup.string().trim().required(requiredError),
      streetName: yup.string().trim().required(requiredError),
      streetNumber: yup.string().trim().required(requiredError),
      postalCode: yup
        .string()
        .required(requiredError)
        .matches(/^\d{2}(-)?\d{3}?$/, postalCodeError),
      city: yup.string().trim().required(requiredError),
    });
  }, [requiredError, postalCodeError]);

  const validateNipId = useCallback(
    (value: string) => {
      return fieldValidation(nipIdSchema, {}, 'nipId', value);
    },
    [fieldValidation, nipIdSchema],
  );

  const validateStreetName = useCallback(
    (value: string) => {
      return fieldValidation(schema, formData, 'streetName', value);
    },
    [fieldValidation, schema, formData],
  );

  const validateStreetNumber = useCallback(
    (value: string) => {
      return fieldValidation(schema, formData, 'streetNumber', value);
    },
    [fieldValidation, schema, formData],
  );

  const validatePostalCode = useCallback(
    (value: string) => {
      return fieldValidation(schema, formData, 'postalCode', value);
    },
    [fieldValidation, schema, formData],
  );

  const validateCity = useCallback(
    (value: string) => {
      return fieldValidation(schema, formData, 'city', value);
    },
    [fieldValidation, schema, formData],
  );

  useEffect(() => {
    setValidNipId(formValidation(nipIdSchema, { nipId: nipId }));
    setValid(formValidation(schema, formData.nipData));
  }, [formValidation, nipIdSchema, schema, nipId, formData]);

  const handleDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formData = form.dataState.value as DataB2B;
    const newNipData = { ...formData.nipData, [e.target.name]: e.target.value };
    const newData = { ...form.dataState.value, nipData: newNipData };
    form.dataState.set(newData);
  };

  const handleNipIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNipId(e.target.value);
    setValidNipId(formValidation(nipIdSchema, { nipId: e.target.value }));
  };

  const handleVerifyNip = async () => {
    setNipDataLoading(true);
    const response = await sdk.callAction<Nip24CompanyInfo>({
      actionName: 'nip24/getData',
      payload: { nipId },
    });
    setNipDataLoading(false);
    if (response.isError) {
      setNipError(true);
    } else {
      if (nipError) setNipError(false); // Clear error if a valid NIP was submitted after an error
      // Here we store the input field value as nipId to be able to restore it later if the user navigates back to this step
      // "nipId" won't be submitted, only "nipData" (contains all the info the backend needs including its own nipId)
      nipData = { ...response.data };
      form.dataState.set({ ...form.dataState.value, nipId, nipData: { ...response.data } });
      setDisableStreetName(!!response.data.streetName);
      setDisableStreetNumber(!!response.data.streetNumber);
      setDisablePostalCode(!!response.data.postalCode);
      setDisableCity(!!response.data.city);
    }
  };

  return (
    <div>
      {/* Intro explanation */}
      <div>
        <p className="text-18 font-bold">{s.b2bStep1IntroSectionTitle}</p>
        <p className="pt-12">{s.b2bStep1IntroSectionDescription}</p>
      </div>

      {/* Customer type selector */}
      <div className="py-32">
        <CustomerTypeSelector
          b2bState={b2bState}
          form={form}
          b2cLabelDesktop={s.b2bStep1CustomerTypeButtonB2CDesktop}
          b2cLabelMobile={s.b2bStep1CustomerTypeButtonB2CMobile}
          b2bLabelDesktop={s.b2bStep1CustomerTypeButtonB2BDesktop}
          b2bLabelMobile={s.b2bStep1CustomerTypeButtonB2BMobile}
        />
      </div>

      {/* Info notice */}
      <div className="flex gap-12 lg:items-center">
        <span className="text-base-accent-1">
          <InfoIcon />
        </span>
        <p className="text-14">{s.b2bStep1NipInfoBoxDescription}</p>
      </div>

      {/* NIP input */}
      <div className={`mt-24 ${nipData && 'flex items-start gap-12'}`}>
        <Input
          name="nipId"
          type="text"
          label={s.b2bStep1NipIdFieldLabel}
          required
          value={nipId}
          onChange={handleNipIdChange}
          wrapperClassName={`${nipData && 'w-full'}`}
          validationSchema={validateNipId}
        />
        <Button
          className={`${nipData ? 'mt-30' : 'mt-24'} h-48 ${!nipData && 'max-sm:w-full'}`}
          loading={nipDataLoading}
          disabled={!isValidNipId}
          onClick={handleVerifyNip}
        >
          {nipData ? s.b2bStep1NipIdFieldButtonRetryVerification : s.b2bStep1NipIdFieldButtonFirstVerification}
        </Button>
      </div>

      {/* Show NIP data from API */}
      {nipData && (
        <div className="flex flex-col gap-24 py-32">
          {/* Company name */}
          <div>
            <span>{s.b2bStep1CompanyLongNameFieldLabel}</span>
            <div className="mt-8 rounded-xl bg-base-accent-3 px-20 py-16 text-neutral-3 lg:rounded-full">
              {nipData.companyNameLong}
            </div>
          </div>

          {/* Street + number */}
          <div className="flex gap-24 max-lg:flex-col lg:gap-12">
            <div className="lg:w-4/5">
              <Input
                name="streetName"
                type="text"
                required
                placeholder={s.b2bStep1StreetNameFieldLabel}
                value={nipData?.streetName ?? ''}
                label={s.b2bStep1StreetNameFieldLabel}
                onChange={handleDataChange}
                validationSchema={validateStreetName}
                wrapperClassName="mt-8 w-full"
                className="!px-24 !py-28 disabled:text-neutral-3"
                disabled={disableStreetName}
              />
            </div>
            <div className="lg:w-1/5">
              <Input
                name="streetNumber"
                type="text"
                required
                placeholder={s.b2bStep1StreetNumberFieldLabel}
                value={nipData?.streetNumber ?? ''}
                label={s.b2bStep1StreetNumberFieldLabel}
                onChange={handleDataChange}
                validationSchema={validateStreetNumber}
                wrapperClassName="mt-8 w-full"
                className="!px-24 !py-28 disabled:text-neutral-3"
                disabled={disableStreetNumber}
              />
            </div>
          </div>

          {/* Zipcode + town */}
          <div className="flex gap-24 max-lg:flex-col lg:gap-12">
            <div className="lg:w-1/2">
              <Input
                name="postalCode"
                type="text"
                required
                placeholder={s.b2bStep1ZipcodeFieldLabel}
                value={nipData?.postalCode ?? ''}
                label={s.b2bStep1ZipcodeFieldLabel}
                onChange={handleDataChange}
                validationSchema={validatePostalCode}
                wrapperClassName="mt-8 w-full"
                className="!px-24 !py-28 disabled:text-neutral-3"
                disabled={disablePostalCode}
              />
            </div>
            <div className="lg:w-1/2">
              <Input
                name="city"
                type="text"
                required
                placeholder={s.b2bStep1CityFieldLabel}
                value={nipData?.city ?? ''}
                label={s.b2bStep1CityFieldLabel}
                onChange={handleDataChange}
                validationSchema={validateCity}
                wrapperClassName="mt-8 w-full"
                className="!px-24 !py-28 disabled:text-neutral-3"
                disabled={disableCity}
              />
            </div>
          </div>
        </div>
      )}

      {/* CTA (only visible after NIP data exists) */}
      {nipData && (
        <div className={`flex items-center gap-20 max-sm:flex-col-reverse max-sm:items-start`}>
          <Button
            className="max-sm:w-full"
            onClick={onClickSubmit}
            disabled={!isValid && !(disableStreetName && disableStreetNumber && disablePostalCode && disableCity)}
          >
            {s.b2bStep1CtaButtonLabel}
          </Button>
          <span className="text-14 text-neutral-3">{s.b2bStep1ConfirmationOfCorrectnessDescription}</span>
        </div>
      )}

      {/* Error message */}
      {nipError && <ErrorFeedback type="nipError" />}
    </div>
  );
};

export default B2BStep1;
